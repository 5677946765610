/** @format */

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans&display=swap");
@import url("//fonts.googleapis.com/earlyaccess/droidarabickufi.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("//fonts.googleapis.com/earlyaccess/droidarabickufi.css");

/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Montserrat", "Poppins", sans-serif;
}

.ant-select-item-option-content,
.ant-select-item-option-state,
.ant-select-item {
	text-transform: capitalize !important;
}

body {
	background-color: #f0f0f0;
}

.centered-calendar {
	position: fixed !important;
	left: 50% !important;
	top: 35% !important;
	transform: translate(-50%, -50%) !important;
	z-index: 9999; /* Adjust if needed */
}

.centered-calendar2 {
	position: fixed !important;
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;
	z-index: 9999; /* Adjust if needed */
}

:root {
	--primaryBlue: #20212c;
	--primaryBlueDarker: #1e2332;
	--orangeDark: #501500;
	--orangeLight: #ffe3d9;
	--mainGrey: #fafafa;
	--darkGrey: #5f5e5e;
	--mainWhite: #fff;
	--mainBlack: #222;
	--mainTransition: all 0.3s ease-in-out;
	--mainSpacing: 0.3rem;

	/* Primary Colors */
	--primary-color: #20212c; /* Base primary color */
	--primary-color-light: #3e4358; /* Slightly lighter shade of primary */
	--primary-color-lighter: #4c536c; /* Lighter shade */
	--primary-color-dark: #1e2332; /* Darker shade */
	--primary-color-darker: #171a26; /* Even darker shade */

	/* Secondary Colors */
	--secondary-color: #501500; /* Dark orange color */
	--secondary-color-light: #733b29; /* Slightly lighter shade of dark orange */
	--secondary-color-lighter: #a84636; /* Lighter shade */
	--secondary-color-dark: #3a0f00; /* Darker shade */
	--secondary-color-darker: #280a00; /* Even darker shade */

	/* Neutral Colors */
	--neutral-light: #fafafa; /* Light grey */
	--neutral-light2: #f0f0f0; /* Slightly darker light grey */
	--neutral-light3: #e8e8e8; /* Another light neutral shade */
	--neutral-medium: #d4d4d4; /* Medium grey */
	--neutral-dark: #5f5e5e; /* Dark grey */
	--neutral-dark2: #444444; /* Another dark grey */
	--neutral-darker: #222222; /* Main black */

	/* Accent Colors */
	--accent-color-1: #ffe3d9; /* Light orange */
	--accent-color-1-light: #fff1eb; /* Lighter shade of accent */
	--accent-color-1-dark: #cca298; /* Darker shade of accent */
	--accent-color-2: #fff; /* Pure white */
	--accent-color-2-light: #f7f7f7; /* Slightly off-white */
	--accent-color-2-dark: #e0e0e0; /* Light grey for accents */
	--accent-color-3: #303443; /* Reused primary color as an accent */
	--accent-color-3-light: #4c536c; /* Lighter shade as an accent */
	--accent-color-3-dark: #1e2332; /* Darker shade as an accent */

	/* Text Colors */
	--text-color-primary: #222; /* Main black for primary text */
	--text-color-secondary: #5f5e5e; /* Dark grey for secondary text */
	--text-color-light: #fafafa; /* Light grey for lighter text */
	--text-color-dark: #1e2332; /* Darker shade for text */

	/* Background Colors */
	--background-light: #fafafa; /* Light background */
	--background-dark: #303443; /* Dark background */
	--background-accent: #ffe3d9; /* Light orange for accent background */

	/* Button Colors */
	--button-bg-primary: #303443; /* Primary button background */
	--button-bg-primary-light: #4c536c; /* Lighter primary button */
	--button-bg-secondary: #501500; /* Secondary button background */
	--button-font-color: #fff; /* White font on buttons */

	/* Border Colors */
	--border-color-light: #e0e0e0; /* Light grey for borders */
	--border-color-dark: #303443; /* Darker border color */

	/* Shadows */
	--box-shadow-light: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
	--box-shadow-dark: 0 2px 4px rgba(0, 0, 0, 0.3); /* Darker shadow */

	/* Transitions */
	--main-transition: all 0.3s ease-in-out;
	--main-spacing: 0.3rem;

	/*Admin Messages*/
	--admin-message-bg: #f0f9ff; /* Light blue for admin */
	--admin-message-color: #004085; /* Dark blue text for admin */
	--user-message-bg: #e8f5e9; /* Light green for user */
	--user-message-color: #1b5e20; /* Dark green text for user */
}

::placeholder {
	font-style: italic;
	font-size: 0.75rem;
	font-weight: bold;
	color: #d3d3d3 !important;
}

.DarkbackgroundForSidebar {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	top: 0px;
	left: 0;
	position: fixed;
	z-index: 100;
	padding: 1200px;
}

.DarkbackgroundForSidebar2 {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	top: 0px;
	left: 0;
	position: fixed;
	z-index: 100;
	padding: 1200px;
}

.arabicFonts {
	font-family: "Droid Arabic Kufi";
}

@media (max-width: 480px) {
	.ant-calendar-range {
		width: 320px !important;
	}
	.ant-calendar-range-part {
		width: 100% !important;
	}
}

@keyframes blink {
	0% {
		opacity: 1; /* Fully visible at the start */
	}
	50% {
		opacity: 0; /* Completely invisible at halfway */
	}
	100% {
		opacity: 1; /* Fully visible at the end */
	}
}
